/* Add this to the top of your CSS file */
body {
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}


.landing-container {
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  font-family: 'Archivo', sans-serif;
  position: relative;
  width: 100%;
}

/* Navigation */
.nav-container {
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}

.ice-logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  background: white;
  padding: 0.75rem 1.25rem;
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.snowflake {
  display: inline-block;
  transform: rotate(45deg);
}

.nav-buttons {
  display: flex;
  gap: 0.75rem;
}

.btn-request {
  padding: 0.75rem 1.25rem;
  border-radius: 100px;
  color: #4B5563;
  background: transparent;
  transition: background-color 0.2s;
}

.btn-request:hover {
  background: rgba(0, 0, 0, 0.05);
}

.btn-sign-in {
  padding: 0.75rem 1.25rem;
  background: white;
  border-radius: 100px;
  color: #111827;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s;
}

.btn-sign-in:hover {
  background: #F9FAFB;
}

/* Hero Section */
.hero-section {
  max-width: 1280px;
  margin: 0 auto;
  padding: 4rem 2rem;
  position: relative;
}

/* Stats Card */
.stats-card {
  position: absolute;
  left: 0;
  top: 30%;
  background: white;
  padding: 1.25rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  width: 280px;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.stat-row:last-child {
  margin-bottom: 0;
}

.stat-label {
  color: #6B7280;
  font-size: 0.875rem;
}

.stat-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #111827;
}

.stat-icon {
  font-size: 1rem;
}

.stat-icon.up {
  color: #3B82F6;
}

.stat-icon.down {
  color: #EF4444;
}

/* Hero Content */
.hero-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.hero-title {
  font-size: 63px; 
  font-weight: 400; /* Regular font weight */
  line-height: 72px;
  color: #111827;
  margin-bottom: 1.5rem;
  font-style: normal; /* Ensures no italic style is applied */
}


.highlight {
  color: #3B82F6;
}

.hero-subtitle {
  font-size: 1.125rem;
  color: #6B7280;
  line-height: 1.6;
  margin-bottom: 2.5rem;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.75rem;
  background: #111827;
  color: white;
  border-radius: 100px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.cta-button:hover {
  background: #1F2937;
}

/* Responsive */
@media (max-width: 1024px) {
  .stats-card {
    display: none;
  }
  
  .hero-title {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle br {
    display: none;
  }
}

@media (max-width: 640px) {
  .nav-container {
    padding: 1rem;
  }
  
  .hero-section {
    padding: 2rem 1rem;
  }
  
  .hero-title {
    font-size: 2rem;
  }
}


/* .image-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
} */
/* 
.uploaded-image {
  max-width: 150%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.animated-text-container {
  margin-top: 229px;
  text-align: left; /* Align the text to the left */
  padding: 0 2rem;
}

.animated-text {
  font-size: 2.5rem;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.2);
  white-space: pre-wrap;
  /* font-weight: 100; */
  /* font-size: 30px; */
  font-family: "Archivo", sans-serif;
}

.animated-text span {
  opacity: 0.2; /* Start with light shade */
  transition: opacity 0.3s ease-in, color 0.3s ease-in;
  color: rgba(0, 0, 0, 0.4); /* Light shade */
}

.animated-text span.visible {
  opacity: 1; /* Fully visible */
  color: rgba(0, 0, 0, 0.9); /* Dark shade */
}

.animated-text span.hidden {
  opacity: 0.2; /* Stay in light shade */
}

/* Previous styles remain the same */

.cards-section {
  margin-top: 4rem;
  padding: 2rem;
  text-align: center;
}

.why-ice-title {
  font-size: 59px;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #111827;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity, transform;
}

.cards-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.card {
  flex: 1;
  max-width: 350px;
  padding: 2rem;
  border-radius: 1rem;
  background: white;
  text-align: left;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-logo {
  width: 48px;
  height: 48px;
  margin-bottom: 1.5rem;
  object-fit: contain;
}

.card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: white;
}

.card p {
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.card {
  padding: 2rem;
  border-radius: 15%; /* 15% corner radius relative to card dimensions */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  color: #ffffff; /* White text for contrast */
  background: linear-gradient(to bottom, #a6dcef, #3b82f6); /* Light to dark blue gradient */
}

/* Individual Card Backgrounds (Optional Customization) */
.card-1 {
  background: linear-gradient(to bottom, #b3e5fc, #3b82f6); /* Light to dark blue */
}

.card-2 {
  background: linear-gradient(to bottom, #b3e5fc, #0288d1); /* Light to dark teal */
}

.card-3 {
  background: linear-gradient(to bottom, #b3e5fc, #3b82f6); /* Light to dark indigo */
}


.card-1 {
  background: linear-gradient(135deg, #b3e5fc 0%, #b3e5fc 50%, #b3e5fc 100%);
}

.card-2 {
  background: linear-gradient(135deg, #b3e5fc 0%, #b3e5fc 50%, #b3e5fc 100%);
}

.card-3 {
  background: linear-gradient(135deg,  #b3e5fc 0%, #b3e5fc 50%, #b3e5fc 100%);
}
/* Responsive adjustments */
@media (max-width: 1024px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
    max-width: 500px;
  }
}

@media (max-width: 640px) {
  .cards-section {
    padding: 1rem;
  }

  .why-ice-title {
    font-size: 2rem;
  }

  .card {
    padding: 1.5rem;
  }
}

/* 1. before ice section */

.before-ice-section {
  margin-top: 4rem;
  padding: 2rem;
  text-align: left;
  background-color: #f9fbff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .before-ice-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
} */
.before-ice h2 {
  font-size: 59px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.claim-tag {
  display: inline-block;
  background-color: #9be3fc;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.claim-description {
  font-size: 1.2rem;
  color: #111827;
  margin-bottom: 2rem;
}

/* .claim-details {
  display: flex;
  justify-content: center;
} */

.claim-image {
  max-width: 55%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.before-ice-section {
  margin-top: 2rem; /* Reduced to align spacing */
  padding: 1.5rem; /* Adjust padding */
  text-align: left;
  background-color: #f9fbff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.before-ice {
  margin-bottom: 1.5rem; /* Reduce space between title and content */
  margin-top: 176px;
}

/* .claim-details {
  display: flex;
  justify-content: center;
  align-items: center; 
  margin: 0; 
} */

.claim-image {
  max-width: 55%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center the image */
}

.before-ice-section {
  margin-top: 4rem;
  padding: 1rem 2rem; /* Reduce padding */
  background-color: #f9fbff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content; /* Container adapts to content */
  margin-left: auto; /* Center horizontally */
  margin-right: auto;
  max-width: 55%;
}

.claim-details {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%; /* Ensure image remains responsive */
}

.claim-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rapid-details {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%; /* Ensure image remains responsive */
}

.rapid-image {
  width: 90%; /* Adjust percentage as needed */
  max-width: 1200px; /* Set a maximum width */
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.rapid-title {
  margin-top: 170px;
  font-size: 59px;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #111827;
  text-align: center;
}

/* testing  */

/* Basic page/container styling */
/* .landing-container {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  margin-left: 64px;
  width: 100%;
} */
.landing-container {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100vw; /* Full viewport width */
  height: 100%; /* Let it grow with the content */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Some space on top so there's a scroll */
.top-content {
  height: 80vh;
  background: #e1e1e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* The area where the window and text are shown */
.scroll-anim-section {
  position: relative;
  height: 100vh; /* enough for us to see the big scale transitions */
  background: #f7f7f7;
  overflow: hidden; /* if the scale gets huge, you can control if it shows or is clipped */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* The window starts normal size (could be 300x200, etc.) 
   Because we transform scale, it might get huge. */
.expanding-window {
  width: 300px;
  height: 200px;
  background: #8a2be2; /* purple */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  text-align: center;

  transition: transform 0.1s linear; 
  /* we do a quick transition so it feels smoother 
     as the scroll updates. 
     If you remove this, it will be 1:1 with scroll. */
}

/* The text that appears and scales as we move from 200→400 scrollY */
.appearing-text {
  margin-top: 2rem;
  transition: transform 0.1s linear, opacity 0.1s linear;
  text-align: center;
  max-width: 600px;
}


/* NEW block for post-rapid animation */
.post-rapid-animation {
  margin-top: 3rem;
  background: #eee;
  text-align: center;
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.2s linear; 
  /* so it doesn't jump abruptly as scroll changes */
}

/* 
  1) Give the post-rapid panel a background color 
     so you can see it expand over the screen.
*/
.post-rapid-panel {
  position: relative;
  width: 100%;
  height: 300px;            /* starting height */
  background: #999;         /* some color, or background image if you prefer */
  transition: all 1s ease;  /* for the expansion animation */
  margin-top: 3rem;
  overflow: hidden;         /* if content extends, hide it */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 2) Once it intersects => go full screen. 
      You could do fixed position if you want it to override everything else. 
      Or just become very tall. 
*/
.post-rapid-panel.panel-expanded {
  position: fixed;   /* so it covers the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 999;
}

/* 
  3) The text inside the panel, which fades in & grows 
*/
.post-rapid-text {
  opacity: 0;
  transform: scale(0.8);
  color: #fff;
  text-align: center;
  transition: opacity 1s ease, transform 1s ease;
  max-width: 600px;
  margin: 0 1rem;
}

/* once panelTextVisible => text becomes visible */
.post-rapid-text.text-visible {
  opacity: 1;
  transform: scale(1);
}

/* Add a new block for the post-rapid section */
.post-rapid-scroll {
  margin-top: 3rem;
  margin-bottom: 3rem;
  background: #ccc;  /* Just to see it; replace with your desired background */
  text-align: center;
  padding: 2rem;
  transition: transform 0.2s linear; /* So scale changes don't jerk instantly */
  border-radius: 8px;
  /* If you want it to maintain some minimum size even at scale(1): */
  width: 80%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}


/* We'll use a gradient background color similar to your hero wave. 
   Adjust as needed: 
   e.g. background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
   or a purple-ish gradient. 
*/

.post-rapid-box {
  margin: 3rem auto;
  width: 300px;
  height: 300px;
  /* Let's do a purple-ish gradient: */
  background: linear-gradient(to bottom right, #a667ee, #c084fc);
  border-radius: 16px;
  /* We'll scale from ~0.3 to ~3.0, so it can fill the screen. */
  transform-origin: center;
  transition: transform 0.1s linear; /* or 0.2s, etc. for smoothing */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* so if content inside is bigger, it's clipped. */
}

/* The text inside, which we fade/scale in once we've scrolled about 30% of the way. */
.post-rapid-content {
  color: #fff;
  text-align: center;
  max-width: 600px;
  padding: 1rem;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform-origin: center;
}


.post-rapid-box {
  width: 300px;
  height: 300px;
  margin: 5rem auto; 
  background: linear-gradient(45deg, #a667ee, #c084fc);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  transition: transform 0.1s linear; /* smooth scale changes */
  overflow: hidden;
}

.box-text {
  color: #fff;
  text-align: center;
  margin: 1rem;
  transform-origin: center;
  transition: transform 0.2s linear, opacity 0.2s linear;
}

/* Make sure this box is pinned in place so it can expand to fill the screen */
.post-rapid-box {
  position: sticky;        /* Sticks to the top while we scroll through its range */
  top: 0;
  width: 100vw;
  height: 100vh;
  transform-origin: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#eee, #fff);
  z-index: 10;

  /* A small transition so scale changes smoothly. 
     You can tweak the duration/curve to taste. */
  transition: transform 0.2s ease-out;
}

/* Each text block inside the box */
.box-text {
  position: absolute;           /* Each text stacked in the center */
  color: #333;
  text-align: center;
  padding: 0 1rem;

  /* Fade/scale transitions */
  transition: 
    opacity 0.2s ease-out, 
    transform 0.2s ease-out;
}

/* Example bigger text styling */
.box-text h3 {
  margin: 0;
  font-size: 1.4rem;
}

/***************************************
  1) Container Behavior
***************************************/
.post-rapid-container {
  position: relative; /* Ensure it's part of the flow initially */
  width: 100%; /* Full width */
  height: 100vh; /* Full height for scrolling to trigger */
  display: flex;
  align-items: center;
  justify-content: center;
  background: none; /* No background initially */
  transition: background 0.5s ease;
}

.post-rapid-container.full-screen {
  position: fixed; /* Fix to viewport during full-screen phase */
  top: 0;
  left: 0;
  background: none;
  z-index: 100; /* Bring to front */
}

/***************************************
  2) Box Behavior
***************************************/
.post-rapid-box.small-box {
  width: 300px;
  height: 300px;
  background-image: url('../icons/background\ image\ \(1\).jpg'); /* Path to the background image */
  background-size: cover; /* Ensure the image covers the entire box */
  background-position: center; /* Center the image */
  border-radius: 8px;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-rapid-box.full-screen-box {
  width: 100vw; /* Full width of viewport */
  height: 100vh; /* Full height of viewport */
  background-image: url('../icons/background\ image\ \(1\).jpg'); /* Same background image */
  background-size: cover; /* Ensure the image covers the full screen */
  background-position: center; /* Center the image */
  border-radius: 0; /* Remove border radius */
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

/***************************************
  3) Text Inside the Box
***************************************/
.box-text-step {
  color: #333;
  font-size: 2rem;
  text-align: center;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.post-rapid-box.full-screen-box .box-text-step {
  opacity: 1; /* Make visible during full-screen */
  transform: scale(1); /* Scale to full size */
}


.text-container {
  text-align: center;
}

.intelligence-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.text-bold {
  margin-right: 10px;
  font-size: 59px;
  font-weight: bold;
  font-family: 'Archivo', sans-serif;
  color: black;
}

.intelligence-icon {
  height: 50px;
  margin-right: 10px;
}

.excellence-text {
  font-size: 59px;
  font-weight: bold;
  font-family: 'Archivo', sans-serif;
  color: black;
}


/***************************************
  New Text Styles
***************************************/
.new-text-container {
  text-align: center;
  /* margin-top: 20px; */
  margin-top: 143px;
  margin-bottom: 44px;
}

.new-text {
  font-size: 59px;
  font-weight: 500;
  color: black;
  margin: 0;
}

.divider {
  margin-top: 10px;
  border: 0;
  border-top: 4px solid black;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.case-5-divider {
  margin-top: 10px;
  border: 0;
  border-top: 4px solid black;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
}


.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: transparent;
  /* background-color: #b3e5fc; */
  /* background:linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%); */
}

.centered-image {
  max-width: 66%;
  max-height: 74%;
  border-radius: 8px; /* Optional, for rounded corners */

}



/* navbar fixing  */

/* General Navigation Container */
.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px; /* Add spacing inside the header */
  background: #ffffff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 45px; /* Rounded corners */
  position: fixed;
  top: 16px; /* Add some margin from the top */
  left: 50%; /* Center align horizontally */
  transform: translateX(-50%);
  width: 77%; /* Adjust width */
  max-width: 1200px; /* Set a maximum width */
  z-index: 1000; /* Ensure it stays above other elements */
}

/* Logo Section */
.logo-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ice-logo {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
}

.ice-logo .snowflake {
  color: #4444ff; /* Blue color for the snowflake */
  margin-right: 8px;
}

/* Navigation Buttons */
.nav-buttons {
  display: flex;
  gap: 12px;
}

.nav-buttons button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.4); /* Subtle border */
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-buttons .btn-request {
  background: white;
  color: #000;
  padding: 13px 26px;
}

.nav-buttons .btn-sign-in {
  background: #f5f5f5;
  color: #888;
  padding: 13px 15px;
}

.nav-buttons button:hover {
  background: black; /* Blue hover effect */
  color: white;
  border-color: #ffffff;
}


.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px; /* Adjust the height of the logo */
  width: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between the logo and text */
}

.logo-text {
  font-size: 34px; /* Adjust the font size */
  font-weight: bold; /* Make the text bold */
  color: #333; /* Adjust the color of the text */
}




/* new before and after -ice section */
.comparison-section {
  margin: 40px 0;
  padding: 20px;
  /* background-color: #f5f9ff; */
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  border-radius: 8px;
}

.tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background: #fff;
  color: #007bff;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.tab-button.active {
  background: #007bff;
  color: #fff;
}

.comparison-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comparison-description {
  margin-bottom: 20px;
}

.comparison-description h3 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 23px;
}

.comparison-images {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.image-section {
  flex: 1;
  text-align: center;
}

.image-section h4,
.image-section h5 {
  margin-bottom: 10px;
  font-weight: bold;
}

.image-section ul {
  list-style: disc;
  padding-left: 20px;
  text-align: left;
}

.image-section img {
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  height: auto;
}


.comparison-images {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.image-section {
  flex: 1;
  text-align: center;
}

.image-section h4 {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
}

.image-section ul {
  list-style: none;
  padding: 0;
  margin: 15px 0 0;
}

.image-section ul li {
  margin-bottom: 10px;
  font-size: 14px;
  position: relative;
  padding-left: 25px;
}

.image-section ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  color: #007bff;
}

.issues-solutions-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  gap: 20px;
}

.issues-section,
.solutions-section {
  flex: 1;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
}


.issues-section ul li,
.solutions-section ul li {
  color: #555;
}

.issues-section {
  background-color: transparent;
  border: none;
}

.solutions-section {
  background-color: transparent;
  border: none;
}


/* Pill Toggle Container */
.pill-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fafafa; Light background for the pill container */
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  border-radius: 30px; /* Fully rounded container */
  padding: 5px;
  max-width: 400px;
  margin: 20px auto; /* Center the container */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border : 1.5px solid black;
}

/* Individual Toggle Buttons */
.pill-toggle-button {
  flex: 1;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #333; /* Default text color for inactive tabs */
  background-color: transparent; /* Transparent for inactive state */
  border: none;
  border-radius: 30px; /* Rounded edges to match the container */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

/* Active Toggle Button */
.pill-toggle-button.active {
  background-color: #b3e5fc; /* Blue background for active button */
  color: #333; /* White text for active button */
  font-weight: bold; /* Bold text for active tab */
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* Subtle shadow for the active button */
}

/* Hover Effect for Inactive Buttons */
.pill-toggle-button:not(.active):hover {
  background-color: rgba(0, 123, 255, 0.1); /* Light blue hover effect */
  color: #333; /* Blue text on hover */
}

/* Divider Below the Toggle */
.section-divider {
  margin: 20px auto;
  width: 90%;
  height: 1px;
  background-color: #e0e0e0; /* Light gray color for the line */
}


/* Issues and Solutions Container */
.issues-solutions-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  gap: 20px;
}

/* Issues and Solutions Sections */
.issues-section,
.solutions-section {
  flex: 1;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Light shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect for Sections */
.issues-section:hover,
.solutions-section:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Stronger shadow */
}

/* Titles for Issues and Solutions */
.issues-section h5,
.solutions-section h5 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
  font-size: 20px;
}

/* List Styling */
.issues-section ul,
.solutions-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.issues-section ul li,
.solutions-section ul li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  align-items: center; /* Align text with icon */
  color: #555; /* Subtle text color */
}

/* Bullet Icons */
.bullet-icon {
  margin-right: 10px; /* Spacing between icon and text */
  font-size: 16px;
  display: inline-block;
}

/* Custom Colors for Issues and Solutions */
.issues-section .bullet-icon {
  color: #ff4d4f; /* Red for issues */
}

.solutions-section .bullet-icon {
  color: #52c41a; /* Green for solutions */
}

/* Image Container */
.image-container-comparison {
  position: relative;
  overflow: hidden; /* Prevents the image from overflowing */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Images */
.image-container-comparison img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

/* Only zoom the hovered image */
.image-container-comparison:hover img {
  transform: scale(2); /* Zoom to 2x */
  cursor: zoom-in; /* Show zoom cursor */
}





/* Expanded Image Overlay */
.expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.expanded-image-overlay img {
  max-width: 113%;
  max-height: 121%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  width: 60%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

/* .close-button:hover {
  background-color: #ff6b6b;
} */



/* Expand Button */
.expand-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* background-color: rgba(0, 123, 255, 0.8); */
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.expand-button img {
  width: 20px;
  height: 20px;
}

/* .expand-button:hover {
  background-color: rgba(0, 123, 255, 1);
} */

/* Expanded Image Overlay */
.expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.expanded-image-overlay img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 24px;
  height: 24px;
}

/* Card Bullet Points */
.card-bullet-points {
  margin: 15px 0;
  padding-left: 20px;
  list-style: none;
}

.card-bullet-points li {
  position: relative;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  color: #555; /* Subtle text color */
}

.card-bullet-points li::before {
  content: '•'; /* Use a custom bullet point */
  position: absolute;
  left: -20px;
  font-size: 20px;
  color: black; /* Use a highlight color */
}

.card h3 {
  margin-bottom: 10px;
  color: #333; /* Bold text color for headings */
}

.card-logo {
  margin-bottom: 10px;
  width: 50px;
  height: auto;
}

/* Enhance the card container for better visuals */
.card {
  background-color: #f9f9f9; /* Light background color */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow */
}


.hero-subtitle-time{
  color: #007bff;
  /* font-weight: bold; */
}

.animated-text span.highlight.visible {
  color: #007bff;
}



/* last section  */
.last-section {
  /* background-color: #f8f9fa; Light gray background to match the design */
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  padding: 89px 134px;
  margin: 50px auto;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 800px; /* Center-align with fixed width */
  border: 1px solid black;
  margin-bottom: 0px;
}

.last-section-title {
  font-size: 61px; /* Bold title */
  font-weight: 500;
  margin-bottom: 10px;
  color: #000; /* Slightly dark gray */
  max-width: 96%;
  margin-left: 15px;
  margin-bottom: 20px;
  line-height: 1;
  -webkit-text-stroke: 0.5px #000;
}

.last-section-subtitle {
  font-size: 16px; /* Subtitle text size */
  color: #666; /* Medium gray text color */
  /* margin-bottom: 20px; */
  margin-top: -12px;
}
.last-section-subtitle-1 {
  font-size: 16px; /* Subtitle text size */
  color: #666; /* Medium gray text color */
  margin-top: -12px;
  margin-bottom: 60px;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: bold;
  background-color: rgb(9, 9, 32);; /* Black button background */
  color: #fff; /* White button text */
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: rgb(7, 7, 64);; /* Slightly lighter black on hover */
}


.video-container {
  text-align: center;
  margin: 20px auto;
}

.centered-video {
  max-width: 50%;
  height: auto;
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}



/* responsive design adjustments */

/* Base styles for the header */
.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 45px;
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 77%;
  max-width: 1200px;
  z-index: 1000;
}

/* For larger screens, allow a larger max width */
@media (min-width: 1440px) {
  .nav-container {
    max-width: 1400px;
  }
}

/* For smaller screens, reduce paddings and font sizes */
@media (max-width: 768px) {
  .nav-container {
    padding: 8px 16px;
    width: 95%;
  }
  .logo-text {
    font-size: 24px;
  }
  .nav-buttons button {
    font-size: 14px;
    padding: 8px 12px;
  }
}


/* Container for the image */
/* Ensure padding is included in width calculations */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Full viewport container that “breaks out” of any parent wrapper */
.image-container {
  width: 100vw;                  /* Full viewport width */
  padding: 0 1rem;               /* Horizontal padding */
  position: relative;            
  left: 50%;                    
  transform: translateX(-50%);   /* Center horizontally */
  box-sizing: border-box;        /* Include padding in width calculation */
  top: 16px;
}


/* Responsive image styling */
.uploaded-image {
  width: 100%;            
  max-width: 1200px;      /* Adjust this value as needed */
  height: auto;           
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;
}

/* Media queries for smaller devices */
@media (max-width: 768px) {
  .uploaded-image {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .uploaded-image {
    max-width: none;
  }
}


.last-section {
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  padding: 60px 40px; /* Reduced base padding */
  margin: 50px auto;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  border: 1px solid black;
}

.last-section-title {
  font-size: 48px; /* A bit smaller than 61px for base */
  font-weight: 500;
  margin-bottom: 20px;
  color: #000;
  line-height: 1.2;
  -webkit-text-stroke: 0.5px #000;
}

.last-section-subtitle,
.last-section-subtitle-1 {
  font-size: 16px;
  color: #666;
  margin-top: 0;
  margin-bottom: 20px;
}

/* Adjust CTA button if needed */
.last-section .cta-button {
  margin-top: 20px;
}

/* Media Query for Tablets and Small Laptops */
@media (max-width: 1024px) {
  .last-section {
    padding: 50px 30px;
    max-width: 90%;
  }
  .last-section-title {
    font-size: 40px;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 640px) {
  .last-section {
    padding: 40px 20px;
    margin: 30px auto;
  }
  .last-section-title {
    font-size: 32px;
  }
  .last-section-subtitle,
  .last-section-subtitle-1 {
    font-size: 14px;
  }
  /* Ensure the CTA button is easily tappable */
  .last-section .cta-button {
    padding: 12px 20px;
    font-size: 14px;
  }
}


/* Hero Title */
.hero-title {
  font-size: 63px; /* Default size for large screens */
  font-weight: 400; /* Regular font weight */
  line-height: 72px;
  color: #111827;
  margin-bottom: 1.5rem;
  font-style: normal; /* Ensures no italic style is applied */
  text-align: center;
  margin-top: 2rem; /* Add space between the navbar and title */
}

/* Responsive adjustments for hero title */
@media (max-width: 1024px) {
  .hero-title {
    font-size: 3rem; /* Adjust size for tablets */
    line-height: 3.5rem;
    margin-top: 4rem; /* Add more space on smaller screens */
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem; /* Smaller size for small devices */
    line-height: 3rem;
    margin-top: 5rem; /* Ensure there's enough space from the navbar */
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem; /* Smallest size for very small devices */
    line-height: 2.5rem;
    margin-top: 6rem; /* Ensure no overlap with the navbar */
  }
}
