.tariff-details-page {
  width: 96%;
}

.tariff-details-page .content {
  padding: 0;
}

.response-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
}

.response-item {
  background-color: #f5f5f5;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
}

.response-item p {
  margin: 5px 0;
}

.response-item p strong {
  font-weight: bold;
}

.pdf-comparison {
  display: flex;
  justify-content: space-around;
  gap: 45px;
}

.pdf-container {
  flex: 1;
}

.pdf-viewer {
  max-width: 100%;
  max-height: 520px; 
  overflow-y: scroll; 
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 5px;
  background: white;
}

.calculation-container {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
}

.calculation-container h3 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}

.calculation-table {
  width: 100%;
  margin-top: 10px;
}

.calculation-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.calculation-row:nth-child(even) {
  background-color: #f9f9f9;
}

.calculation-row.highlight {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.calculation-row .label {
  font-weight: 15px;
}

.calculation-row .value {
  text-align: right;
}

 .button-group {
  display: flex;
  justify-content: flex-end;
  gap:229px;
  margin-top: 20px; 
  padding-bottom: 20px; 
}

.button-group button {
  background-color: #333; 
  color: #fff; 
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none; 
  font-weight: 500;
  font-size: .9375rem;
}

.button-group .secondary-button {
  background-color: #f0f0f0;
  color: #333;
}

.button-group button:hover {
  background-color: #555;
}

.button-group .secondary-button:hover {
  background-color: #ddd;
}