/* Overall Page Layout */
.login-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background-color: #ffffff;
  }
  
  /* Logo Section */
  .login-logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align logo to the left */
    padding-left: 2rem; /* Add spacing from the left edge */
    /* margin-bottom: 2rem; Add spacing below the logo */
    margin-top: 20px;
  }
  
  .login-logo-image {
    height: 32px;
    width: auto;
  }
  
  .login-logo-text {
    font-size: 1.25rem;
    font-weight: 500;
    color: #111827;
    margin-left: 0.5rem;
  }
  
  /* Form Section */
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-left: 500px;
  }
  
  .login-form {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
  }
  
  .login-form-group {
    margin-bottom: 1rem;
  }
  
  .login-form-group label {
    font-size: 1rem;
    color: #111827;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .login-form-group input {
    width: 155%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #e5e7eb;
    border-radius: 9999px;
    outline: none;
    transition: border-color 0.2s ease;
  }
  
  .login-form-group input::placeholder {
    color: #9ca3af;
  }
  
  .login-form-group input:focus {
    border-color: #2563eb;
    background-color: #ffffff;
  }
  
  /* Remember Me Row */
  .remember-me-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    color: #111827;
  }
  

  
  .login-button:hover {
    background-color: #090942;
  }
  
  /* Centered Links */
  .centered-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-left: 50px;
    text-align: center;
  }
  
  /* Forgot Password Link */
  .forgot-password {
    font-size: 0.875rem;
    color: #2563eb;
    margin-left: 146px;
  }
  
  .forgot-password a {
    text-decoration: none;
    color: #2563eb;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  
  /* Divider */
  .login-divider {
    font-size: 0.875rem;
    color: #6b7280;
    text-align: center;
    margin-left: 140px;
    margin-bottom: 15px;
  }
  
  .login-divider span {
    background-color: #ffffff;
    padding: 0 0.5rem;
  }
  
  /* Apply for Access Button */
  .login-access-button {
    background-color: #ffffff;
    color: #0a0a2a;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem;
    border-radius: 9999px;
    border: 1px solid #e5e7eb;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    width: 242%;
    max-width: 507px;
    margin-left: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .login-access-button:hover {
    background-color: #f3f4f6;
    color: #090942;
  }
  


  /* Form Section */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-left: 500px; /* Keep this for alignment */
  }
  
  .login-form {
    width: 100%;
    max-width: 400px; /* Set a fixed max-width for the form */
    display: flex;
    flex-direction: column;
  }
  
  .login-form-group input {
    width: 160%; /* Make inputs take 100% of the form width */
    max-width: 400px; /* Prevent inputs from exceeding the form width */
    padding: 0.75rem 1rem;
    font-size: 1rem;
    /* background-color: #f9f9f9; */
    border: 1px solid #e5e7eb;
    border-radius: 9999px;
    outline: none;
    transition: border-color 0.2s ease;
    background-color: transparent;
  }
  
  /* Sign In Button */
  .login-button {
    background-color: #0a0a2a;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem;
    border-radius: 9999px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: 140%; /* Button takes full form width */
    max-width: 432px; /* Prevent button from exceeding form width */
    margin-bottom: 2rem;
    margin-left: 2px;
  }
  
  /* Centered Links */
  .centered-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-left: 130px; /* Keep this for alignment */
    max-width: 400px; /* Prevent centered-links from expanding too far */
  }
  

  /* hidding the passowrd */
  .password-input-container {
    position: relative;
  }
  
  .password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 140%;
  }
  
  .password-input-wrapper input {
    width: 100%;
    padding-right: 40px; /* Add padding to prevent text overlap with the icon */
  }
  
  .password-toggle-icon {
    position: absolute;
    right: 23px;
    cursor: pointer;
    color: #666; /* Adjust the color as needed */
    top: 10px;
  }
  
  .password-toggle-icon:hover {
    color: #000; /* Change color on hover */
  }



  .login-access-button {
    background-color: #ffffff;
    color: #0a0a2a;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem;
    border-radius: 9999px;
    border: 1px solid #e5e7eb;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    width: 171%;
    max-width: 507px;
    margin-left: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}


.password-input-wrapper {
  position: relative;
  display: flex
;
  align-items: center;
  width: 171%;
  max-width: 432px;
}

.login-button {
  background-color: #0a0a2a;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 171%;
  max-width: 432px;
  margin-bottom: 2rem;
  margin-left: 2px;
}


.login-access-button {
  background-color: #ffffff;
  color: #0a0a2a;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem;
  border-radius: 9999px;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  width: 170%;
  max-width: 432px;
  margin-left: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}


