.upload-section {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
}

.upload-section h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.upload-box {
  border: 2px dashed #007bff;
  /* padding: 20px; */
  text-align: center;
  margin-bottom: 10px;
  background-color: #fafafa;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.upload-box.dragging {
  background-color: #e6f7ff;
  border-color: #1890ff;
}

.custom-file-input {
  display: none; /* Hide the default file input */
}

.custom-file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Manrope', sans-serif; /* Use your preferred font */
  font-size: 16px;
  text-align: center;
}

.upload-text {
  color: #333;
}

.upload-icon {
  width: 70px;
  height: auto;
}

.browse-link {
  color: #007bff;
  text-decoration: underline;
}

.file-info {
  margin-top: 10px;
}

.file-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  transition: box-shadow 0.3s;
}

.file-item:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.file-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-icon {
  width: 24px;
  height: 24px;
}

.file-name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.cancel-button {
  background: none;
  border: none;
  color: #d9534f;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.3s;
}

.cancel-button:hover {
  color: #c9302c;
}

.progress-bar {
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  height: 8px;
  width: 100%;
}

.progress {
  height: 100%;
  background-color: #ffcc80;
  transition: width 0.1s;
}

.uploading .upload-button {
  opacity: 0.5;
  pointer-events: none;
}

.upload-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

.upload-text {
  color: #333;
  margin-top: 0; /* Ensure there is no margin at the top */
}

p {
  margin-top: 0; /* Remove any top margin from the paragraph */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
