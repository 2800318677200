/* .audit-details-page {
    padding: 20px 40px;
    margin-left: 106px;
  }


  .audit-details-page .content {
    padding: 20px;
  }
  
  .audit-details-page .breadcrumb {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .audit-details-page .breadcrumb a {
    color: #007bff;
    text-decoration: none;
  }
  
  .audit-details-page .breadcrumb a:hover {
    text-decoration: underline;
  }
  
  .audit-details-page h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .response-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .response-item {
    background-color: #f5f5f5;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .response-item p {
    margin: 5px 0;
  }
  
  .response-item p strong {
    font-weight: bold;
  }
  
  .pdf-comparison {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .pdf-viewer {
    flex: 1;
    max-width: 50%;
    max-height: 600px; /* Set a maximum height for the scrollable container */
    /* overflow-y: scroll; /* Enable vertical scrolling */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    background: white;
    width: 25px;
  } */ 

  .audit-details-page {
    padding: 20px 40px;
    /* margin-left: 106px; */
  }
  .audit-details-page{
    width: 96%;

  }
  .audit-details-page .content {
    padding: 0px;
  }
  
  .audit-details-page .breadcrumb {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .audit-details-page .breadcrumb a {
    color: #007bff;
    text-decoration: none;
  }
  
  .audit-details-page .breadcrumb a:hover {
    text-decoration: underline;
  }
  
  .audit-details-page h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .response-container {
    border-radius: 8px;
    margin-bottom: 20px;
    margin-left: 22px;
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  }
  
  .response-item {

    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
    background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
    width: 96%;
    }
      
  
  
  .response-item p {
    margin: 5px 0;
  }
  
  .response-item p strong {
    font-weight: bold;
  }
  
  .pdf-comparison {
    display: flex;
    justify-content: space-around;
    gap: 30px;
    margin-left: 18.5px;
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  }
  
  .pdf-container {
    flex: 1;
    width: 25px;
  }
  
  .audit-details-pdf-viewer {
    max-width: 100%;
    max-height: 520px; 
    overflow-y: scroll; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    background: white;
    
  }
  
  .button-group-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .revert-button-container {
    margin-right: 800px;
  }
  
  .revert-button-container  .revert-button {
    background-color: #d9534f;
    color: #fff; 
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
    transition: background-color 0.3s ease;
    cursor: pointer;
    border: none; 
    font-weight: 500;
    font-size: .9375rem;
  }
  
  .revert-button:hover {
    background-color: #c9302c; 
  }
  
  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    position: relative;
  }
  
  .button-group .confirm-discrepancy-btn {
    position: relative;
  }
  
  .button-group .floating-confirm-box {
    position: absolute;
    bottom: 100%; 
    left: 50%;
    transform: translateX(-50%) translateY(-10px); 
    background-color: white;
    padding: 10px 15px;
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    z-index: 1000;
    width: 220px; 
    text-align: center;
    font-size: 0.85em;
    line-height: 1.4;
  }
  
  .button-group .floating-confirm-box:before {
    content: "";
    position: absolute;
    bottom: -10px; 
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  .button-group .floating-confirm-box p {
    margin: 0;
    font-weight: bold;
  }
  

  .toggle-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggle-button {
    background-color: #f0f0f0;
    border-radius: 25px;
    border: 2px solid #000;
    display: flex;
    cursor: pointer;
    width: 381px;
    padding: 2px;
    position: relative;
    height: 31px;
  }
  
  .toggle-option {
    flex: 1;
    text-align: center;
    border-radius: 25px;
    transition: all 0.3s ease;
    font-weight: bold;
    z-index: 1;
    padding-top: 3px;
  }
  
  .toggle-option.active {
    background-color: #000;
    
    
    color: #fff;
  }
  
  .toggle-option.inactive {
    color: #000;
  }
  
  .toggle-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: #000;
    border-radius: 25px;
    transition: all 0.3s ease;
    z-index: 0;
  }
  
  .toggle-container .toggle-button .toggle-background.left {
    left: 0;
  }
  
  .toggle-container .toggle-button .toggle-background.right {
    left: 50%;
  }
  
  .pdf-comparison {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Ensure relative positioning for the containers */
}

.pdf-container {
  flex: 1;
  padding: 10px;
}

.plus-button-container {
  position: absolute; /* Make sure the button is absolute inside the parent */
  left: 50%; /* Horizontal centering */
  transform: translateX(-50%); /* Fine-tune centering */
  z-index: 1; /* Ensure the button appears above other elements */
}

.plus-button {
  width: 37px;
  height: 37px;
  font-size: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);

}

.plus-button:hover {
  background-color: #0056b3;
}


.pdf-viewer {
  position: relative;
}

.crop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
  z-index: 10;
}

.crop-button {
  margin-top: 10px;
}


.floating-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  max-width: 400px;
  width: 100%;
}

.floating-box h3 {
  margin-top: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.floating-box label {
  display: block;
  margin-top: 10px;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.floating-box input,
.floating-box select,
.floating-box textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.floating-box button {
  margin-top: 15px;
  margin-right: 10px;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.audit-details-page {
  padding: 20px;
}

.pdf-comparison {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pdf-container {
  flex: 1;
  padding: 10px;
}

.between {
  /* margin-left: 10px;
  margin-right: 10px;
  width: 50px;
  height: 50px; */
  border-radius: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.between:hover {
  background-color: #0056b3;
}

.plus-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.crop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
  z-index: 10;
}

.floating-box {
  position: fixed;
  top: 60%;
  left: 70%;
  transform: translate(-50%, -50%);
  background-color: #f3f3f3;
  border: 0.5px solid #5289ff;
  padding: 12px;
  z-index: 1000;
  max-width: 400px;
  width: 100%;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  border-radius: 15px;

}

.floating-box h3 {
  margin-top: 0;
}

.floating-box label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

.floating-box input,
.floating-box select,
.floating-box textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.floating-box button {
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 15px;

}


.revert-button-container {
  display: flex;
  align-items: center; /* Align button and icon vertically */
}

.audit-info-icon {
  width: 25px; /* Icon size */
  height: 25px;
  margin-left: 8px; /* Space between the button and icon */
  cursor: pointer;
}

.audit-info-icon:hover::after {
  content: attr(title); /* Display tooltip */
  position: absolute;
  bottom: 150%; /* Position tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.auditinfo-icon img {
  margin-bottom: 10px;
}


.plus-button-container {
  display: flex;
  flex-direction: column; /* Stack the button and icon vertically */
  align-items: center;
  justify-content: center;
  position: absolute; /* Position relative to the parent container */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for the button's width */
  z-index: 2; /* Ensure it appears on top */
}

.plus-button {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 33%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.plus-button:hover {
  background-color: #0056b3;
}

.audit-info-icon-next-to-plus {
  margin-top: 5px; /* Add space between the button and icon */
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.audit-info-icon-next-to-plus:hover::after {
  content: attr(title); /* Tooltip text */
  position: absolute;
  bottom: 40px; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 3;
}

.pdf-comparison {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Needed for absolute positioning of the plus button container */
  margin-top: 20px;
  gap: 45px;
}
canvas.hiddenCanvasElement {
  display: none !important;
  visibility: hidden !important;
}
