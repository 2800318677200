.upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full page height */
    text-align: center;
    margin: auto;
  }
  
  .dropzone {
    width: 600px;
    height: 400px;
    border: 2px dashed #007bff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #f9f9f9;
  }
  
  .upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-icon {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .browse-link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .file-formats {
    margin-top: 10px;
    color: #888;
  }
  
  .file-info {
    width: 100%;
    margin-top: 20px;
  }
  
  .file-preview {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* background-color: #f0f0f0; */
    border-radius: 5px;
    width: 400px;
  }
  
  .file-details {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .file-icon {
    width: 30px;
    height: 30px;
  }
  
  .file-name {
    font-weight: bold;
  }
  
  .file-progress {
    font-size: 14px;
    color: #007bff;
  }
  


  .upload-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  

  .processing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 255, 0.3);
    border-radius: 50%;
    border-top-color: #007bff;
    animation: spin 1s ease-in-out infinite;
    margin-top: 20px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .json-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    width: 100%;
    height: 610px !important;
  }
  
  .json-textarea {
    width: 724px !important;
    height: 476px !important;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    font-family: 'Manrope';
    font-size: 14px;
    /* min-height: calc(1.5em + .75rem + 2px); */
    margin-left: 50px;
  }
  
  .json-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 40px;
  }
  
  .json-download-button, .json-copy-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    cursor: pointer;
  }
  
  .json-download-button {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    font-family: 'Manrope';

  }
  
  .json-copy-button {
    background-color: #f1f1f1;
    color: black;
    border-radius: 5px;
    font-family: 'Manrope';

  }
  

  .name {
    margin-right: 650px;
    font-family: 'Manrope';

  }


  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-menu {
    position: absolute;
    background-color: #f1f1f1;
    /* min-width: 160px; */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    /* padding: 10px; */
    border-radius: 5px;
    width: 94px;
    margin-left: 9px;
  }
  
  .dropdown-menu button {
    background-color: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
  
  .dropdown-menu button:hover {
    background-color: #ddd;
  }
  