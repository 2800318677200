/* Overall Page Layout */
.access-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    min-height: 100vh;
  }
  
  /* Logo Container */
  .access-logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align logo to the left */
    padding-left: 2rem; /* Add spacing from the left edge */
    margin-bottom: 2rem; /* Add spacing below the logo */
  }
  
  .access-logo {
    height: 32px;
    width: auto;
  }
  
  .logo-text {
    font-size: 1.25rem;
    font-weight: 500;
    color: #111827;
    margin-left: 0.5rem;
  }
  
  /* Form Container */
  .access-container {
    max-width: 480px;
    margin: 0 auto;
    text-align: center; /* Center-aligns text within the form container */
    background-color: white; /* Optional: adds contrast for form container */
    padding: 2rem;
    border-radius: 12px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); Optional shadow for form */
    margin-left: 428px;
  }
  
  /* Title Styling */
  .access-title {
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 3rem;
    color: #111827;
  }
  
  /* Form Layout */
  .access-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .name-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-start;
    margin-left: 17px;
  }
  .form-group-email {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-start;
    margin-left: 17px;
  }
  
  .form-group label {
    font-size: 1rem;
    font-weight: 400;
    color: #111827;
  }
  .form-group-email label {
    font-size: 1rem;
    font-weight: 400;
    color: #111827;
  }
  
  .form-group input {
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 22px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.2s ease;
    background-color: white;
  }
  .form-group-email input {
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 22px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.2s ease;
    background-color: white;
    width: 98.5%;
  }
  
  .form-group input::placeholder {
    color: #9ca3af;
  }
  
  .form-group input:focus {
    border-color: #2563eb;
    box-shadow: 0 0 0 1px rgba(37, 99, 235, 0.1);
  }
  
  .access-button {
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    background-color: rgb(9, 9, 32);
    color: white;
    border: none;
    border-radius: 9999px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: 100%;
    margin-left: 16px;
  }
  
  .access-button:hover {
    background-color: rgb(7, 7, 64);;
  }
  
  .access-button:active {
    background-color: rgb(9, 9, 32);
    transform: translateY(1px);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 640px) {
    .name-row {
      grid-template-columns: 1fr;
    }
  }
  