.pdf-comparison-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    min-height: 100vh;
    width: 100%; /* Full width of the container */
    margin: 0 auto;
}

h2 {
    color: #333;
    margin-bottom: 20px;
}

.pdf-viewers {
    display: flex;
    flex-direction: row; /* Display PDFs side by side */
    justify-content: space-around; /* Space between the PDFs */
    align-items: flex-start; /* Align the PDFs to the top */
    gap: 20px; /* Space between the two containers */
    width: 100%;
    max-width: 1400px; /* Ensure a maximum width for the container */
    margin-bottom: 20px;
    flex-wrap: nowrap; /* Prevent wrapping of the viewers */
}

.pdf-viewer {
    width: 45%; /* Ensure the viewers are side by side and take up 45% of space */
    height: 700px; /* Set a height for the container to ensure scrolling */
    overflow-y: auto; /* Ensure the PDF content is scrollable */
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
    padding: 10px;
    border-radius: 10px;
    background: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.pdf-viewer h3 {
    text-align: center;
    color: black;
    margin-bottom: 10px;
    flex-shrink: 0;
}

.action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 0;
    z-index: 1000;
}

.dropdown-menu button {
    display: block;
    width: 100%;
    padding: 5px 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
}

.dropdown-menu button:hover {
    background-color: #f0f0f0;
}

.download-button, .copy-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 90px;
}

.download-button:hover, .copy-button:hover {
    background-color: #0056b3;
}

.copy-button {
    background-color: #28a745;
}

.copy-button:hover {
    background-color: #218838;
}

@media (max-width: 1024px) {
    .pdf-viewers {
      flex-direction: column; /* Stack vertically on smaller screens */
      align-items: center;
    }

    .pdf-viewer {
      width: 90%; /* Adjust width for smaller screens */
      margin-bottom: 20px;
      height: 600px; /* Adjust height for smaller screens */
    }
}
