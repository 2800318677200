.animation-page {
  padding: 40px; /* Adjusted padding for better alignment */
  position: relative; /* Ensure it is positioned relative for absolute positioning of child elements */
}

.animation-page header {
  margin-bottom: 10px; /* Adjusted margin for better spacing */
}

.animation-page h1 {
  font-size: 28px; /* Increased font size */
  font-weight: bold;
}

.lottie-container {
  display: flex;
  justify-content: flex-start; /* Align the animation to the left */
  margin-bottom: -14px;
  margin-left: -8px;
}

.lottie-container div {
  width: 70px !important; /* Ensure width */
  height: 70px !important; /* Ensure height */
  overflow: hidden !important;
  margin: 0 !important; /* Remove default margin */
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 25px;
  overflow: hidden;
  margin: 10px 0; /* Adjust margin to reduce the gap between elements */
}

.progress {
  height: 25px;
  background-color: #5289ff;
  width: 0%;
  transition: width 0.5s ease;
}

.animation-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0; /* Adjusted margin to reduce the gap */
}

.animation-list li {
  font-size: 20px; /* Increased font size */
  margin: 20px 0; /* Adjusted margin for proper spacing */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.animation-list li span:first-child {
  flex-grow: 1; /* Ensure the text spans the available space */
  margin-right: 20px; /* Increased margin to separate text from checkmark */
}

.checkmark {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  color: #5289ff; /* Adjust the color as needed */
  font-size: 24px; /* Increased font size for better visibility */
  margin-left: 10px; /* Added margin to separate checkmark from text */
}

.checkmark.checked {
  opacity: 1;
}

.checkbox {
  display: inline-block;
  font-size: 24px; /* Increased font size for better visibility */
  color: #5289ff; /* Adjust the color as needed */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.checkbox.checked {
  opacity: 1;
}


/* froze version css */

.animation-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin-left: 438px;
  text-align: center;
  flex-direction: column;
}

.waiting-container h2 {
  margin-top: 20px;
  font-size: 1.5rem;
  color: #333;
}

.waiting-container p {
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
}

.error-message {
  color: red;
  font-size: 1rem;
  margin-bottom: 20px;
}
