.custom-header {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-right: 0px;
}

header h1 {
  color: black;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure the child elements stretch to the same height */
  margin-bottom: 20px;
  gap: 19px;
  width: 100%; /* Ensure the header content uses the full width of its container */
}

.claim-info {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensure the child elements are centered vertically */
  flex: 1. 1;
  margin-right: 20px;
  gap: 10px;
  border: 1px solid #eaecf0; /* Border around claim-info */
  padding: 10px; /* Padding inside the border */
  padding-right: 18.1px ;
  border-radius: 15px; /* Optional: rounded corners */
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
}

.claim-item {
  display: flex;
  align-items: center;
}

.icon {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  margin-right: 10px;
  background-size: cover;
}

.user-icon {
  background-image: url('../icons/user-icon.svg');
}

.hospital-icon {
  background-image: url('../icons/hospital-icon.svg');
}

.diagnosis-icon {
  background-image: url('../icons/diagnosis-icon.svg');
}

.claimant-name, .hospital-name, .primary-diagnosis, .diagnosis-code {
  font-size: 16px;
  margin: 0;
  max-width: 206px;
}

.fraud-risk {
  text-align: left;
  font-size: 13px;
  color: #666;
  margin-top: 2px; /* Reduced margin to bring it closer to the claimant name */
}

.low-risk {
  background-color: #e0f7fa;
  color: green;
  padding: 2px 5px;
  border-radius: 5px;
}

.tag {
  background-color: #e0f7fa;
  color: green;
  padding: 2px 5px;
  border-radius: 5px;
  border: 0px;
}



.claim-amounts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  gap: 10px;
  border: 1px solid #eaecf0; /* Border around claim-amounts */
  padding: 10px; /* Padding inside the border */
  border-radius: 15px; /* Optional: rounded corners */
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
}

.claim-amounts p {
  margin: 0;
}

.summary-item {
  text-align: center;
}

.discrepancy-info {
  display: flex;
  justify-content: space-between; /* Align items on the left and right */
  align-items: flex-start; /* Align items at the top */
  padding: 10px;
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  border-radius: 15px;
  border: 0.5px solid #5289ff;
  width: 98.5%;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
}

.discrepancy-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.discrepancy-details-content {
  flex: 1; /* Takes up the remaining space */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px; /* Adds some space between details and amount */
}

.summary-item.discrepancy-amount {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  padding: 10px;
  text-align: center; /* Align text to the center */
  white-space: nowrap; /* Prevent text wrapping */
  margin: 0; /* Removes default margin */
}

.summary-item.discrepancy-amount p {
  margin: 1px 0; /* Ensures consistent spacing between the heading and amount */
}

.discrepancy-icon {
  margin-right: 10px;
  margin-top: 19px;
}

.discrepancy-icon img {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
}
.custom-header .claim-info-link {
  display: flex;
  align-items: center;
}
.info-icon {
  width: 16px; /* Adjust the size as needed */
  height: 16px;
  margin-right: 8px; /* Space between the icon and the text */
  vertical-align: middle; /* Aligns the icon with the text */
  border-radius: 50%; /* This is to match the circular look if it's an SVG or icon with round shape */
}


.alert-icon {
  display: inline-block;
  margin-left: 10px;
  position: relative;
}

.alert-icon img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.alert-icon .tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;  /* Closer positioning */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s, bottom 0.3s;
  width: 300px;  /* Adjust width as necessary */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.alert-icon .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.alert-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
  bottom: 140%;  /* Closer transition */
}


.discrepancy-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.discrepancy-details {
  font-size: 14px;
  color: #555;
  margin: 5px 0 0;
}

.claim-info-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.claim-info-link h1 .breadcrumb-link {
  color: black;
}



.input-group input {
  padding: 10px 35px 10px 10px; /* Add padding to the right for the icon */
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  font-size: 14px;
  position: relative;
  background: url('../icons/send-icon.svg') no-repeat right 10px center; /* Add the send icon */
  background-size: 20px 20px; /* Adjust the icon size */
}

.input-group .secondary-button {
  background-color: #333; /* Change the background color to black */
  color: #fff; /* Change the text color to white */
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none; /* Remove border */
  font-weight: 500;
  font-size: .9375rem;
}

.input-group .secondary-button:hover {
  background-color: #555; /* Change hover background color to a lighter shade */
}

/* Removing line separators between claim info items */
.claim-info .claim-item:not(:last-child) {
  padding-right: 10px;
  margin-right: 10px;
}

/* Removing line separators between claim amounts items */
.claim-amounts .summary-item:not(:last-child) {
  padding-right: 10px;
  margin-right: 10px;
}

.summary-item.discrepancy-amount {
  padding: 10px;
  text-align: center;
  white-space: nowrap; /* Prevent the discrepancy amount from expanding vertically */
  padding-top: 0px;
}

/* Removing line separator between discrepancy content and amount */
.discrepancy-details-content:not(:last-child) {
  padding-right: 0px;
  margin-right: 40px;
  flex: 1; /* Allow this section to take available space */
}

.response-items p {
  margin: 5px 0;
  font-size: 15px;
  text-align: justify; /* Ensures text is justified */
}

.response-items p strong {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.discrepancy-details-content p {
  margin: 1px 0;
  font-size: 15px;
  display: inline; /* Ensure the read more starts on the same line */
}

.expanded-details p {
  font-size: 15px;
  margin-top: -4px;
}

.discrepancy-details-content p strong {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  display: inline; /* Ensure the read more starts on the same line */
}

.read-more {
  color: #007bff;
  cursor: pointer;
}

.read-more:hover {
  text-decoration: underline;
}

/* Adding line separators between claim info items */
.claim-info .claim-item:not(:last-child) {
  border-right: 1px solid #ddd;
  padding-right: 10px;
  margin-right: 10px;
}

/* Adding line separators between claim amounts items */
.claim-amounts .summary-item:not(:last-child) {
  border-right: 1px solid #ddd;
  padding-right: 38px;
  margin-right: 10px;
}


.input-group {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.secondary-button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 0.9375rem;
}

.secondary-button:hover {
  background-color: #555;
}

.input-group .prev-button {
  background-color: #ddd !important; /* Set the background color to #ddd and use !important to override */
  color: #333; /* Set the text color to contrast with the background */
}

.input-group .prev-button:hover {
  background-color: #ccc !important; /* Slightly darker shade on hover */
}


.discrepancy-header {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  margin-bottom: 10px; /* Adds space below the header */
}

.info-icon {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  margin-top: 14px;
}

.discrepancy-text {
  display: flex;
  flex-direction: column;
}

.discrepancy-text p {
  margin: 2; /* Removes the margin to align with the icon */
}


.blacklisted-risk {
  color: red;
  font-weight: bold;
}


.editable-amount {
  margin-top: -9px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.editable-amount input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-size: 16px;
  width: 100px;
  text-align: right;
  margin-bottom: 8px; /* Add some space between the input and the tag */
  box-shadow: none; /* Remove any default shadow */
  outline: none; /* Remove default outline */
}

.editable-amount input:focus {
  border-color: #007bff; /* Change border color when input is focused */
}

/* .tag {
  background-color: #e0f7fa;
  color: #007bff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
} */

.edit-icon {
  margin-left: 8px; /* Space between the text and the icon */
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.editable-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.amount-container {
  display: flex;
  align-items: center;
}

.edit-icon-inline {
  margin-left: 8px;
  cursor: pointer;
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
}


.discrepancy-header {
  display: flex;
  align-items: center;
  gap: 5px; /* Add space between the text and the icon */
}

.discrepancy-info-icon {
  width: 20px; /* Adjust the size of the icon */
  height: 20px;
  cursor: pointer;
}

.discrepancy-info-icon:hover::after {
  content: attr(title); /* Tooltip for the icon */
  position: absolute;
  bottom: 100%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}


/* img.discrepancy-info-icon {
  margin-top: 1px;
} */

.discrepancy-header {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 5px; /* Add space between text and icon */
  margin-bottom: 10px; /* Add consistent spacing below the header */
}

.discrepancy-info-icon {
  width: 16px; /* Set a consistent size for the icon */
  height: 16px;
  cursor: pointer;
  margin-top: 2px; /* Fine-tune alignment vertically */
}

.tooltip-box {
  position: absolute;
  top: -100%; /* Position below the pencil icon */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  background-color: #fff; /* Black background */
  color: #000; /* White text */
  padding: 8px 12px; /* Padding for better readability */
  border-radius: 5px; /* Rounded corners */
  font-size: 14px; /* Adjust text size */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  white-space: nowrap; /* Prevent wrapping of text */
  z-index: 10; /* Ensure it appears on top of other elements */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.amount-container:hover .tooltip-box,
.amount-container:focus-within .tooltip-box {
  opacity: 1;
  visibility: visible;
}

