/* ClaimDetailsPage.css */
.claim-details-page {
  padding: 20px 40px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.claim-info {
  text-align: left;
}

.claimant-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 400px;
}

.claimant-details {
  text-align: left;
}

.claimant-details p {
  font-size: 18px; /* Adjust font size */
  margin-top: 23px 0px;
}

.fraud-risk {
  text-align: left;
  /* margin-left: 20px; */
}

.fraud-risk p {
  font-size: 18px; /* Adjust font size */
  margin: 0;
  font-weight: bold;
}

.low-risk {
  color: green;
}

.claim-summary {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  margin-bottom: 20px;
  grid-column: span 2;
  gap: 30px;
}

.summary-item {
  text-align: left;

}

.summary-item .amount {
  font-weight: bold;
  font-size: 20px;
}

.details-section {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 170px;
  margin-top: 20px;
}

.audit-results {
  border: 1px solid #eaecf0;
  border-radius: 15px;
  padding: 51px;  /* Adjusted padding */
  /* background-color: #f9f9f9; */
  margin-left: 19.5px;
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  width: 88%;
  padding-top: 30px;
  
}

.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;  /* Reduced padding */
  border-bottom: 1px solid #eee;
  font-size: 18.5px;
  position: relative;  /* Add this line */
  font-weight: 500;
}

.result-item:last-child {
  border-bottom: none;
}

.result-item .discrepancies {
  color: red;
  margin-left: 5px;
}

.status {
  font-size: 24px;
  margin-left: 10px;  /* Adjusted margin */
}

.status.approve {
  color: green;
}

.status.deny {
  color: red;
}



/* Tooltip CSS */
.result-item .tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;  /* Closer positioning */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s, bottom 0.3s;
  width: 300px;  /* Adjust width as necessary */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
  font-size: 14px; /* Font size for tooltip */
}

.result-item .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.result-item:hover .tooltip {
  visibility: visible;
  opacity: 1;
  bottom: 140%;  /* Closer transition */
}


.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: inherit; /* Inherits the color of the parent element */
  cursor: pointer;
}

.documents {
  border: 1px solid #eaecf0;
  border-radius: 15px;
  padding: 20px;
  /* background-color: #f9f9f9; */
  justify-self: end;
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
}

.documents ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.documents ul li {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.documents ul li a {
  text-decoration: none;
  color: #1a73e8;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.documents ul li a:hover {
  text-decoration: underline;
}

.documents ul li .document-icon {
  width: 24px;  /* Adjust size to be more visible */
  height: auto;  /* Maintain aspect ratio */
  margin-right: 8px;
  fill: currentColor; /* This ensures the icon inherits the color of the text */
}


.result-item .status {
  margin-left: 10px;
}

.status.approve {
  color: green;
}

.status.deny {
  color: red;
}

.tooltip {
  display: none;
}

.result-item:hover .tooltip {
  display: block;
}

.status-image {
  width: 24px;  /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  margin-left: 10px;  /* Adjusted margin */
  padding-bottom: 20px;
}


.result-item-link {
  color: black; /* Default color */
  text-decoration: none; /* No underline by default */
}

.result-item-link:hover {
  color: #5289ff; /* Change to blue on hover */
  text-decoration: underline; /* Add underline on hover */
}

 .frozen {
  /* pointer-events: none; */
  opacity: 0.6;
  cursor: not-allowed;
} 

.frozen-document {
  color: gray;
  cursor: not-allowed;
}

/* .frozen .status-image {
  filter: grayscale(100%);
} */


.result-item.discrepancy-summary {
  padding-bottom: 5px; 
  font-weight: bold; /* Make the text bold */
  /* border-bottom: 1px solid #000; */
  /* margin-top: 20px; Add margin above the item to create space before the line */
}


.discrepancy-summary-container {
  border: 1px solid #eaecf0;
  border-radius: 15px;
  padding: 30px; /* Adjust padding for spacing */
  margin-bottom: 20px; /* Space between other sections */
  /* background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%); */
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  width: 54.5%;
  margin-left: 20px;
  padding-right: 8px;
  padding-bottom: 15px;
  padding-left: 51.5px;
  padding-top: 15px;
}



.discrepancy-summary {
  font-size: 18px;
  font-weight: 500;
  color: black; /* Match text color to design */
  height: 34px;
}

.discrepancy-summary .result-item {
  padding: 10px 0; /* Adjust padding for the items */
}

.discrepancy-summary .status-image {
  margin-left: 5px;
  width: 24px; /* Same size as in the rest of the component */
  height: 24px;
}


.ice-deductions-heading {
  font-size: 22px; /* Adjust the font size */
  font-weight: bold; /* Make it bold */
  margin-bottom: 20px; /* Add space below the heading */
  color: black; /* Adjust the color */
  text-align: left; /* Align to the left */
  border-bottom: 2px solid #eee; /* Optional: Add a bottom border for separation */
  /* padding-bottom: 10px; Padding for the bottom */
 padding: 0;
}



/* Adjusted styles for valid documents */
.valid-document {
  cursor: default; /* Default cursor to indicate non-clickable */
}

.valid-document .result-item-link,
.valid-document .non-clickable-document {
  color: #888; /* Slightly lighter text color to indicate non-interactivity */
  text-decoration: none;
  pointer-events: none; /* Disable pointer events */
}

.valid-document .result-item-link:hover,
.valid-document .non-clickable-document:hover {
  color: #888;
  text-decoration: none;
}

/* Keep the status icon (tick mark) at full opacity */
.valid-document .status-image {
  opacity: 1;
}




.custom-tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  font-size: 12px;
}

.result-item.frozen:hover .custom-tooltip {
  display: block;
}
/* Parent container for each document row */
.result-item {
  position: relative; /* So the tooltip is positioned relative to this item */
}

/* The tooltip itself */
.freeze-tooltip {
  position: absolute;
  top: 100%;       /* Below the parent */
  left: 0;         /* Aligned left */
  z-index: 9999;   /* Bring it above other elements */
  background: #fff;
  color: #333;
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  /* optional: add box-shadow for better visibility */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap; /* or pre-line, depending on your preference */
}
