/* SettlementLetter.css */

.settlement-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 31px;
    height: 220vh;
  }
  
  .settlement-header {
    border-bottom: 2px solid #e2e2e2;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
  }
  
  .company-logo {
    height: 50px;
  }
  
  .claim-info {
    text-align: right;
  }
  
  .settlement-main {
    margin-top: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .settlement-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .settlement-table th,
  .settlement-table td {
    border: 1px solid #ccc;
    padding: 8px;
  }
  
  .settlement-table th {
    background-color: #f5f5f5;
    text-align: left;
  }
  

  @media print {
    /* Styles specific to PDF/Print */
    body {
        margin: 20px;
    }
    .sidebar, .non-printable {
        display: none;
    }
}
