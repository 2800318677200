.app {
  display: flex;
  height: 100vh;
  background-color: #FFFFFF;
  color: #333;
}

.sidebar {
  width: 60px; /* Set the width to show only icons */
  transition: width 0.3s; /* Smooth transition for expanding */
  background-color: #FCFCFD; /* Light beige or off-white background */
  padding: 20px 0; /* Adjust padding to accommodate icon-only view */
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
  position: relative; /* To keep it on top of other elements */
}

.sidebar:hover {
  width: 200px; /* Expand to full size on hover */
  padding: 20px; /* Restore original padding */
}

.sidebar-logo {
  font-size: 32px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the logo */
  width: 100%;
}

.sidebar-logo img {
  width: 30px; /* Adjust size as needed */
  height: auto;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-menu li {
  margin: 15px 0;
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  color: #333;
  justify-content: center; /* Center the icon */
  position: relative;
}

.sidebar-menu li img {
  margin-right: 0; /* Remove right margin */
  width: 20px; /* Adjust size as necessary */
  height: 20px; /* Adjust size as necessary */
  transition: margin-right 0.3s; /* Smooth transition for margin */
}

.sidebar-menu  li img.ai {
  margin-right: 0; /* Remove right margin */
  width: 29px; /* Adjust size as necessary */
  height: 29px; /* Adjust size as necessary */
  transition: margin-right 0.3s; /* Smooth transition for margin */
}
.sidebar-menu  li img.extract-icon {
  margin-right: 0; /* Remove right margin */
  width: 29px; /* Adjust size as necessary */
  height: 29px; /* Adjust size as necessary */
  transition: margin-right 0.3s; /* Smooth transition for margin */
}

.sidebar-menu li span, .sidebar-menu li .chevron-icon {
  display: none; /* Hide the text and chevron icon */
  transition: display 0.3s;
  margin-left: 10px; /* Add margin for spacing when text is displayed */
}

.sidebar:hover .sidebar-menu li {
  justify-content: flex-start; /* Align items to the start on hover */
}

.sidebar:hover .sidebar-menu li img {
  margin-right: 10px; /* Restore margin on hover */
}

.sidebar:hover .sidebar-menu li span, .sidebar:hover .sidebar-menu li .chevron-icon {
  display: inline; /* Show the text and chevron icon on hover */
}

.sidebar-menu li:hover {
  background-color: #e6e6e6; /* Light gray for hover effect */
  /* color: #ffcc00; Yellow color for text on hover */
  color: #5289ff;
}

.sidebar-menu li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background-color: #5289ff; /* Yellow color */
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.3s;
}

.sidebar-menu li:hover::before {
  transform: scaleY(1);
}

.menu-item {
  position: relative;
}

.menu-item:hover .submenu {
  display: block; /* Show the submenu on hover */
}

.menu-item .submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none; /* Hide submenu by default */
  position: absolute;
  left: 0;
  top: 100%; /* Position it directly below the parent item */
  background-color: #f8f7f2; /* Match the sidebar background color */
  width: 100%; /* Ensure it matches the width of the parent item */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Add a slight shadow for better visibility */
  z-index: 1; /* Ensure it appears above other elements */
}

.menu-item .submenu li {
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  padding: 10px;
  color: #5a5a5a;
  width: calc(100% - 0.02px); /* Match the width of the parent item minus padding */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.menu-item .submenu li:hover {
  background-color: #e6e6e6; /* Light gray for hover effect */
  color: #5289ff;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  width: 143%;
}

header {
  margin-bottom: 20px;
}

.upload-page-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  align-content: stretch;
  flex-direction: column;
}

header h1 {
  font-size: 24px;
  margin-bottom: 5px;
}

header p {
  font-size: 14px;
  color: #555;
}

.upload-sections {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Two columns */
  gap: 20px;
}

.next-button {
  margin-top: 20px;
  padding: 10px 20px;
  /* background-color: #5289ff; */
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s;
  border: 0.5px solid #5289ff;
  align-self: flex-end;
  background: linear-gradient(180deg, rgba(82, 137, 255, 0.05) 0%, rgba(111, 186, 241, 0.05) 100%);
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.12);
  color: black;
}

.next-button:hover {
  background-color: #cddcfc;
}

.selected {
  color: #ffcc00; /* Yellow color for selected item */
  font-weight: bold;
}

.chevron-icon {
  transition: transform 0.3s ease; /* Smooth rotation */
}

.chevron-icon.rotate {
  transform: rotate(90deg); /* Rotate when open */
}


.disabled {
  pointer-events: none; /* Prevent clicking */
  opacity: 0.5; /* Add visual indicator */
  cursor: not-allowed;
}


.email-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.email-popup-content h2 {
  margin-bottom: 10px;
}

.email-popup-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.email-popup-content button {
  background: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.email-popup-content button:hover {
  background: #0056b3;
}

.email-popup-content p {
  font-size: 12px;
  font-weight: 500;
}



/* notification for engine starting css  */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  overflow: hidden;
  z-index: 1000;
}

.notification-content {
  /* padding: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
}

.info-icon {
  color: #3b82f6;
  margin-left: 10px;
  font-size: 16px;
  margin-top: 50px;
}

.notification-text {
  flex-grow: 1;
  color: #4b5563;
  /* margin: 0 10px; */
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  margin-bottom: 5px;
}

.close-icon {
  color: #9ca3af;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
}

.close-icon:hover {
  color: #6b7280;
}

.progress-bar {
  width: 100%;
  height: 3px;
  background-color: #5c8ae6;
  position: relative;
  overflow: hidden;
}

.progress {
  width: 0;
  height: 100%;
  background-color: #0078d4;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  50% {
    width: 80%;
  }
  100% {
    width: 100%;
  }
}